import * as React from 'react'
import { CenteredCard } from '../../components/shared/card'
import Layout from '../../layout'
import { MediumHeader } from '../../components/shared/header'
import { AnimationTypes } from '../../components/shared/section/animations'
import { DiamondSection } from '../../components/shared/section'
import { ServicesContainer, ServicesWrapper } from './styles'

import * as services from '../../content/services.yml'
import { graphql } from 'gatsby'

export default class ServicesPage extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout location={'/services'}>
        <MediumHeader title={'Unsere Leistungen'}
                      srcSet={this.props.data.headerBackground.childImageSharp.fluid.srcSet}/>
        <CenteredCard centered label={'Wir unterstützen Sie bei Ihrer individuellen Lösung'}
                      headline={'Leistungen'}/>

        <ServicesWrapper>
          <ServicesContainer>
            {services.map((service, index) =>
              <DiamondSection key={index}
                              animate={AnimationTypes.FromBottom}
                              diamondBackground={this.props.data[service.image].childImageSharp.fluid.srcSet}
                              backgroundSize={'contain'}
                              reversed={index % 2 === 0}
                              headline={service.title}
                              list={service.info}/>,
            )}
          </ServicesContainer>
        </ServicesWrapper>
      </Layout>
    )
  }
}

export const ServicesQuery = graphql`{
    headerBackground: file(name: {eq: "concrete"}) {
        childImageSharp {
            fluid(rotate: 90) {
                srcSet
            }
        }
    }
    kellerbau: file(relativePath: { eq: "images/services/kellerbau.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    massivbau: file(relativePath: { eq: "images/services/massivbau.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    bodenplatten: file(relativePath: { eq: "images/services/bodenplatten.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    fahrsilos: file(relativePath: { eq: "images/services/fahrsilos.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    hallenunterbauten: file(relativePath: { eq: "images/services/hallenunterbauten.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    stuetzwaende: file(relativePath: { eq: "images/services/stuetzwaende.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    planung: file(relativePath: { eq: "images/services/planung.jpg" }) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
}
`
