export const AnimationThreshold = -500;

const FromBottomAnimation = {
  variants: {
    hidden: {
      opacity: 0,
      top: '50px',
      transition: {
        ease: 'easeInOut',
      },
    },
    true: {
      opacity: 1,
      top: '0px',
      transition: {
        ease: 'easeInOut',
      },
    },
  },
  initial: 'hidden',
}

const FromLeftAnimation = {
  variants: {
    hidden: {
      opacity: 0,
      right: '50px',
      transition: {
        ease: 'easeInOut',
      },
    },
    true: {
      opacity: 1,
      right: '0px',
      transition: {
        ease: 'easeInOut',
      },
    },
  },
  initial: 'hidden',
}

export const AnimationTypes = {
  FromBottom: FromBottomAnimation,
  FromLeft: FromLeftAnimation
}
