import styled from 'styled-components'
import { Fonts } from '../../core/fonts'
import { Device } from '../../core/responsive'
import { Colors } from '../../core/colors'
import { motion } from 'framer-motion'

export const Section = styled(motion.section)([`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  z-index: -2;
  
  &:last-of-type{
    margin-bottom: 80px;
  }
  
  `,
  ``], props => props.background ? `
     background: ${props.background};
     box-shadow: 0 15px 45px -9px rgba(0,0,0,.1);
     margin-bottom: 50px;
     text-align: center;
     
     @media (${Device.laptop}){
      text-align: start;
     }
  ` : '')


export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  
  @media (${Device.laptop}){
    margin: 50px 0;
  }
`

export const Diamond = styled.div`
  width: 200px;
  height: 200px;
  transform: rotate(-45deg);
  overflow: hidden;
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
  
  @media (${Device.laptop}){
    width: 300px;
    height: 300px;
  }
`

export const Image = styled.img`
  width: 150%;
  height: 150%;
  margin: -50px;
  transform: rotate(45deg);
  
  @media (${Device.laptop}){
      margin: -75px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  max-width: 100%;
  justify-content: center;

  @media (${Device.laptop}){
    max-width: 50%;
  }
`

export const Headline = styled.h2`
  position: relative;
  font-weight: 700;
  font-size: 2rem;
  color: ${Colors.text};
  font-family: ${Fonts.default};
  font-stretch: condensed;
  margin-bottom: 24px;
  word-wrap: break-word;
`

export const Subline = styled.h3`
  position: relative;
  font-weight: 300;
  font-size: 1.7rem;
  color: ${Colors.text};
  font-family: ${Fonts.default};
  font-stretch: condensed;
  margin-bottom: 24px;
  word-wrap: break-word;
`

export const Text = styled.p`
  color: ${Colors.text};
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-bottom: 24px;
`


export const Footnote = styled.h3`
    position: relative;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: ${Colors.primary};
    font-family: ${Fonts.default};
    font-stretch: condensed;
    margin-bottom: 24px;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 920px;
  justify-content: space-between;
  flex-direction: column;
  margin: 50px 10px 0 10px;
  
  @media (${Device.laptop}){
    margin: 0;
    flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'};
  }
`

export const Logo = styled.img`
    max-width: 150px;
    height: auto;
    box-shadow: 0 15px 45px -9px rgba(0,0,0,.1);
`
