import React from 'react'
import ReactDOM from 'react-dom'
import {
  Container,
  Diamond,
  Footnote,
  Headline,
  Image,
  ImageContainer,
  Section,
  Subline,
  Text,
  TextContainer,
  Logo
} from './styles'
import { AnimationThreshold } from './animations'


export default class DiamondSection extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      inViewport: false,
      animation: !!props.animate,
      reveal: props.reveal,
    }
  }

  componentDidMount() {
    if (this.state.animation && this.state.reveal) {
      window.addEventListener('scroll', () => this.scrollAnimation(), false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.scrollAnimation(), true)
  }

  scrollAnimation() {
    if (this.state.inViewport) {
      return
    }

    let node = ReactDOM.findDOMNode(this)
    let elementTop = node.getBoundingClientRect().top
    let elementBottom = node.getBoundingClientRect().bottom

    window.requestAnimationFrame(() => {
      this.setState({
        inViewport: (elementTop >= 0) && (elementBottom <= (window.innerHeight - AnimationThreshold)),
      })
    })
  }

  render() {
    const { background, diamondBackground, reversed, headline, subline, text, footnote, animate, backgroundSize, list, logo } = this.props
    const animation = Object.assign(animate || {}, { animate: !!this.state.inViewport || !this.state.reveal })

    return (
      <Section {...animation} background={background}>
        <Container reversed={reversed}>
          <ImageContainer>
            <Diamond>
              <Image backgroundSize={backgroundSize} srcSet={diamondBackground}
                     alt={'Amann Massiv- & Betonbau'}/>
            </Diamond>
          </ImageContainer>
          <TextContainer>
            {headline ? <Headline>{headline}</Headline> : null}
            {subline ? <Subline>{subline}</Subline> : null}
            {text ? <Text>{text}</Text> : null}
            {list ? list.map(info => <Text>{info}</Text>) : null}
            {footnote ? <Footnote>{footnote}</Footnote> : null}
            {logo ? <Logo srcSet={logo}/> : null}
          </TextContainer>
        </Container>
      </Section>
    )
  }
}

